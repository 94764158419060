require("./ActionRequired.scss");
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import Action from "./Action";
import RedFlagIcon from "../Shared/icons/RedFlagIcon";
import {
  filterBySearch,
  getHelpRequestsSum,
  getIncStaleItemsSum,
  getReadyToFinishSum,
} from "../../utils/Utils";
import { setActionRequiredId } from "src/store/features/applications/applicationsSlice";

const ActionRequired = () => {
  const applications = useAppSelector(
    (state) => state.applications.applications
  );
  const searchTerms = useAppSelector((state) => state.applications.searchTerms);
  const selectedActionId = useAppSelector(
    (state) => state.applications.selectedActionId
  );
  const helpRequests = useAppSelector(
    (state) => state.applications.helpRequests
  );
  const dispatch = useAppDispatch();

  const filteredApps =
    applications || helpRequests
      ? filterBySearch(applications, searchTerms)
      : [];

  const helpRequestsSum =
    (applications && helpRequests && getHelpRequestsSum(filteredApps)) || 0;
  const incStaleItemsSum =
    (applications && getIncStaleItemsSum(filteredApps)) || 0;
  const readyToFinishSum =
    (applications && getReadyToFinishSum(filteredApps)) || 0;
  const actions = [
    {
      id: "avb-actions-total",
      text: "Total \n Actions",
      value: helpRequestsSum + incStaleItemsSum + readyToFinishSum,
    },
    {
      id: "avb-actions-help-requests",
      text: "Help \n Requests",
      value: helpRequestsSum,
    },
    {
      id: "avb-actions-inc-stale-items",
      text: "Incomplete \n Stale Items",
      value: incStaleItemsSum,
    },
    {
      id: "avb-actions-ready-to-finish",
      text: "Ready to \n FINISH",
      value: readyToFinishSum,
    },
  ];

  const onActionClick = (actionId) => {
    dispatch(setActionRequiredId(actionId));
  };

  return (
    <div id="avb-actions-required">
      <div className="action-heading">
        <RedFlagIcon className="flag-icon" />
        <span>ACTION REQUIRED</span>
      </div>
      <div className="actions-container">
        {actions.map((action) => (
          <Action
            action={action}
            actionClick={onActionClick}
            key={action.id}
            selected={action.id === selectedActionId}
          />
        ))}
      </div>
    </div>
  );
};

export default ActionRequired;
