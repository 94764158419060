const DollarIcon = () => {
  return (
    <span role="img" className="anticon">
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-425.000000, -992.000000)" fill="#31343A">
            <g transform="translate(419.000000, 980.000000)">
              <g transform="translate(6.000000, 8.000000)">
                <g transform="translate(0.000000, 4.000000)">
                  <path d="M8,0 C12.4171875,0 16,3.5828125 16,8 C16,12.4171875 12.4171875,16 8,16 C3.5828125,16 0,12.4171875 0,8 C0,3.5828125 3.5828125,0 8,0 Z M8,2.66666667 C7.72385763,2.66666667 7.5,2.88374073 7.5,3.15151515 L7.5,3.63636364 C6.11928813,3.63636364 5,4.72173394 5,6.06060606 C5,7.39947818 6.11928813,8.48484848 7.5,8.48484848 L7.5,11.3939394 C6.67157288,11.3939394 6,10.7427172 6,9.93939394 C6,9.67161952 5.77614237,9.45454545 5.5,9.45454545 C5.22385763,9.45454545 5,9.67161952 5,9.93939394 C5.00165306,11.2776015 6.11997342,12.3620334 7.5,12.3636364 L7.5,12.8484848 C7.5,13.1162593 7.72385763,13.3333333 8,13.3333333 C8.27614237,13.3333333 8.5,13.1162593 8.5,12.8484848 L8.5,12.3636364 C9.88071187,12.3636364 11,11.2782661 11,9.93939394 C11,8.60052182 9.88071187,7.51515152 8.5,7.51515152 L8.5,4.60606061 C9.32842712,4.60606061 10,5.25728279 10,6.06060606 C10,6.32838048 10.2238576,6.54545455 10.5,6.54545455 C10.7761424,6.54545455 11,6.32838048 11,6.06060606 C10.9983469,4.72239847 9.88002658,3.6379666 8.5,3.63636364 L8.5,3.15151515 C8.5,2.88374073 8.27614237,2.66666667 8,2.66666667 Z M8.5,8.48484848 C9.32842712,8.48484848 10,9.13607067 10,9.93939394 C10,10.7427172 9.32842712,11.3939394 8.5,11.3939394 L8.5,8.48484848 Z M7.5,4.60606061 L7.5,7.51515152 C6.67157288,7.51515152 6,6.86392933 6,6.06060606 C6,5.25728279 6.67157288,4.60606061 7.5,4.60606061 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
export default DollarIcon;
