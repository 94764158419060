import { ApplicationProcess } from "./Application";
import { Issue, ScreeningStatusId } from "./Screening";

export interface Applicant {
  id: number;
  authenticationId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  suffix: string;
  emailAddress: string;
  phoneNumber: string;
  phoneNumberType: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  stateLicenseIssuer: string;
  gender: string;
  householdStatus: string;
  notEmployed: boolean;
  annualIncome: number;
  nonUSPhone: boolean;
  isSSN: boolean;
  ssnValidForWorkOnly: boolean;
  isMiddleName: boolean;
  applicantTypeId: ApplicantType;
  createdBy: number;
  createdDate: string;
  smsPreference: boolean;
  emailPreference: boolean;
  modifiedBy: number;
  modifiedDate: string;
  applicantEmployer: ApplicantEmployer[];
  applicationProcess: ApplicationProcess;
  applicantInvite: any | null;
  applicantScreening: ApplicantScreening[];
}

export enum ScreeningVendorId {
  ONFIDO = 1,
  FADV,
  SNAPPT,
}

export enum ScreeningVendor {
  ONFIDO = "Onfido",
  FADV = "FADV",
  SNAPPT = "Snappt",
}

export enum ScreeningType {
  BACKGROUND = "Background",
  IDENTITY = "Identity",
  SNAPPT = "Snappt",
}

export interface ApplicantScreening {
  id: number;
  tenantId: number;
  applicantId: number;
  screenVendorApplicantId: string;
  screeningVendor: ScreeningVendor;
  screeningVendorId: ScreeningVendorId;
  screeningType: string;
  screeningTypeId: number;
  screeningStatus: string;
  screeningStatusId: ScreeningStatusId;
  isSoftDeleted: boolean;
  noOfAttempts: number;
  createdOn: string;
  createdBy: number;
  modifiedDate: string;
  modifiedBy: number;
  issues: Issue[];
}

export interface ApplicantEmployer {
  id?: number;
  employer: string;
  industry: string;
  employerPhone: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export enum ApplicantType {
  Applicant = 1,
  CoTenant,
  Guarantor,
  Dependent,
}
