// import React from 'react';

const RedFlagIcon = ({ className }) => {
  return (
    <svg
      width="12px"
      height="16px"
      viewBox="0 0 12 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Icon/flag Copy 2</title>
      <g
        id="Desktop--MVP"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="0.0.1--Dashboard-header-help-actions-02"
          transform="translate(-44.000000, -547.000000)"
          fill="#F5222D"
        >
          <g id="Tooltip" transform="translate(32.000000, 473.000000)">
            <g
              id="Icon/flag-Copy-2"
              transform="translate(10.500000, 74.000000)"
            >
              <path
                d="M2.5,15.3333333 L3.50790642,15.3333333 C3.78404879,15.3333333 4.00790642,15.1094757 4.00790642,14.8333333 L4.00790642,8.18053927 L4.00790642,8.18053927 L12.9142351,8.18053927 C13.1903775,8.18053927 13.4142351,7.95668165 13.4142351,7.68053927 C13.4142351,7.56134542 13.3716545,7.44607245 13.2941693,7.35550081 L10.7932489,4.43220008 L10.7932489,4.43220008 L13.2941693,1.50889934 C13.4736831,1.29906748 13.4491054,0.983440466 13.2392735,0.80392668 C13.1487019,0.726441509 13.0334289,0.683860883 12.9142351,0.683860883 L2.5,0.683860883 C2.22385763,0.683860883 2,0.907718508 2,1.18386088 L2,14.8333333 C2,15.1094757 2.22385763,15.3333333 2.5,15.3333333 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RedFlagIcon;
