import { Fragment, useState } from "react";
import { capitalize, formatDate, formatCurrency } from "src/utils/Utils";
import CancelApplicationModal from "./CancelApplicationModal";
import ResetHoldClockModal from "./ResetHoldClockModal";
import HoldClock from "../Shared/HoldClock";
import { Button } from "react-bootstrap";
import {
  ApplicationHoldEventId,
  ApplicationStatusText,
  MonthlyCharge,
  ReduxApplication,
} from "src/types/Application";
import { Applicant } from "src/types/Applicant";

interface LeftPanelProps {
  application: ReduxApplication;
  refreshApplicationData: any;
}

const LeftPanel = ({ application, refreshApplicationData }: LeftPanelProps) => {
  const {
    communityName,
    completeUnitId,
    primaryApplicantFirstName,
    primaryApplicantLastName,
    moveInDate,
    leaseTerm,
    applicants,
    monthlyCharges,
    parking,
    applicationHold,
  } = application;
  const [selectedApplicant, setSelectedApplicant] = useState<Applicant>();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showResetHoldClockModal, setShowResetHoldClockModal] = useState(false);

  const { applicationStatus } = application;
  const openCancelApplication = () => {
    setShowCancelModal(true);
    const applicant = applicants.filter(
      (a) =>
        a.firstName === primaryApplicantFirstName &&
        a.lastName === primaryApplicantLastName
    );

    if (applicant && applicant.length > 0) {
      setSelectedApplicant(applicant[0]);
    }
  };

  const resetHoldClock = () => {
    setShowResetHoldClockModal(true);
  };

  const showHoldClock = // isClockRunning equivalent
    applicationHold &&
    applicationHold.updateEventId !==
      ApplicationHoldEventId.CANCELLED_APPLICATION &&
    applicationHold.updateEventId !==
      ApplicationHoldEventId.SCREENING_APPLICATION;

  const canExtendHoldClock = // isExtendable equivalent
    applicationHold &&
    showHoldClock &&
    !applicationHold.isExpired &&
    applicationHold.updateEventId !== ApplicationHoldEventId.ADMIN_ADJUSTED;

  const renderHoldClock = (application) => {
    if (!showHoldClock) {
      return <></>;
    }

    return (
      <div className="left-panel-hold-clock-container">
        <HoldClock application={application} type={"detail"} />
        <Button
          className="reset-button"
          variant="outline-light"
          disabled={!canExtendHoldClock}
          onClick={resetHoldClock}
        >
          Reset Clock
        </Button>
      </div>
    );
  };

  const nonCancellableStatuses = [
    ApplicationStatusText.COMPLETED,
    ApplicationStatusText.CANCELLED,
    ApplicationStatusText.LEASE_READY,
    ApplicationStatusText.LEASE_PENDING,
    ApplicationStatusText.LEASE_SIGNED,
    ApplicationStatusText.LEASE_DECLINED,
    ApplicationStatusText.LEASE_EXPIRED,
  ];
  const isCancellable = !!!nonCancellableStatuses.find(
    (value) => value === applicationStatus
  );

  // include `status` field as override to MonthlyCharge `amount` and include parking `status` as needed
  interface RenderedMonthlyCharge extends MonthlyCharge {
    status?: string;
  }
  let renderedMonthlyCharges: RenderedMonthlyCharge[] = [
    ...(monthlyCharges || []),
  ];
  if (parking && !parking.optInApplicantId) {
    // Display parking status field since monthly charges won't contain an amount
    renderedMonthlyCharges = [
      ...monthlyCharges,
      {
        label: "Parking charge/rent",
        chgCode: "PKD",
        amount: 0,
        header: "",
        status: (parking.optOutApplicantId && "Opted-Out") || "Not Selected",
      },
    ];
  }

  return (
    <div className="col-sm-12 col-md-3 left-panel">
      <h2 className="row heading text-uppercase my-3">
        Unit APPLICATION Information
      </h2>
      {renderHoldClock(application)}
      <div className="label">Community</div>
      <div className="text">{capitalize(communityName)}</div>
      <div className="label">Unit</div>
      <div className="text">{completeUnitId}</div>
      <div className="label">Primary Applicant</div>
      <div className="text">{`${primaryApplicantFirstName} ${primaryApplicantLastName}`}</div>
      <div className="label">Move-In Date</div>
      <div className="text">{formatDate(moveInDate)}</div>
      <div className="label">Lease Term</div>
      <div className="text">{leaseTerm}</div>
      {renderedMonthlyCharges
        ? renderedMonthlyCharges
            .sort((a, b) =>
              a.label === "Total Monthly Charges" ? -1 : b.amount - a.amount
            )
            .map((charge, index) => {
              const { label, chgCode, header, amount, status } = charge;
              // render `status` if present instead of `amount`
              let feeFieldContent: JSX.Element | string | null =
                status ||
                `${formatCurrency(amount)}${
                  header?.toLowerCase() === "monthly" ? "/monthly" : ""
                }`;
              if (
                parking &&
                parking.optInApplicantId &&
                parking.reservationUrl &&
                chgCode.toLowerCase().includes("pkd")
              ) {
                feeFieldContent = (
                  <a
                    id="parking-reservation"
                    style={{ color: "#00a3e0" }}
                    href={`${parking.reservationUrl}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {feeFieldContent}
                  </a>
                );
              }

              return (
                <Fragment key={`charge-${index}`}>
                  <div className="label">{label}</div>
                  <div className="text">{feeFieldContent}</div>
                </Fragment>
              );
            })
        : null}
      <div className="divider"></div>
      {isCancellable && (
        <button className="cancel" onClick={openCancelApplication}>
          Cancel this Application
        </button>
      )}
      <CancelApplicationModal
        selectedApplicant={selectedApplicant}
        selectedApplication={application}
        onCancel={() => setShowCancelModal(false)}
        show={showCancelModal}
      />
      <ResetHoldClockModal
        selectedApplication={application}
        onCancel={() => setShowResetHoldClockModal(false)}
        show={showResetHoldClockModal}
        refreshApplicationData={refreshApplicationData}
      />
    </div>
  );
};

export default LeftPanel;
