import { Navigate, Outlet, useLocation } from "react-router-dom";

import {
  initCrossTabLogout,
  isAuthenticated,
  receiveCredentials,
} from "../../utils/Auth";

const PrivateRoute = () => {
  const location = useLocation();
  const isRoot =
    location.pathname == "/AdminDashboard" ||
    location.pathname == "/AdminDashboard/";

  // initialize cross tab logout mechanism
  // NOTE: Since we are copying creds across tabs, we need to have a way
  //          to logout session from all tabs when one of the screens log out.
  initCrossTabLogout();

  // Attempt to fetch credentials from another tab if sent
  receiveCredentials();

  // Unauthorized - redirect to login
  if (!isAuthenticated()) {
    return <Navigate to={`/AdminDashboard/Login?${location.pathname}`} />;
  }

  // Authorized - redirect to dashboard
  if (isAuthenticated() && isRoot) {
    return <Navigate to="/AdminDashboard/Applications" />;
  }

  // Authorized - show component
  return <Outlet />;
};

export default PrivateRoute;
