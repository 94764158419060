const CancelIcon = () => {
  return (
    <span role="img" className="anticon">
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.088 1.726a1.46 1.46 0 0 0 .069 1.997l2.275 2.274-2.278 2.278a1.46 1.46 0 0 0 1.033 2.494l.105-.004c.346-.025.676-.172.928-.424L6.5 8.064l2.28 2.279a1.457 1.457 0 0 0 2.066 0l.066-.071a1.46 1.46 0 0 0-.069-1.998L8.567 6l2.279-2.276a1.46 1.46 0 0 0-.003-2.069l-.07-.065a1.46 1.46 0 0 0-1.995.07L6.499 3.933 4.22 1.655a1.46 1.46 0 0 0-2.067.003l-.064.068z"
          fill="#F5222D"
          fillRule="evenodd"
        />
      </svg>
    </span>
  );
};
export default CancelIcon;
