import { useState } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { isAuthenticated, storeCredentials } from "../../utils/Auth";
import { getConfigs } from "../../configs/Config";
import Alert from "react-bootstrap/Alert";

const Login = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { cognitoHostedUiUrl, redirectToHostedUi } = getConfigs();
  const [user, setUser] = useState({ email: "", password: "" });
  const [showError, setShowError] = useState(false);

  const destination = search.substring(1)
    ? search.substring(1)
    : "/AdminDashboard/Applications";
  const redirect = `${cognitoHostedUiUrl}&state=${destination}`;

  const loginSubmit = (e) => {
    e.preventDefault();
    fetchToken(user);
  };

  // fetch users token
  const fetchToken = (user) => {
    AuthService.getToken(user)
      .then((response) => response.json())
      .then((response) => {
        storeCredentials(response);
        navigate(destination);
      })
      .catch(() => {
        setShowError(true);
      });
  };

  if (isAuthenticated()) {
    return <Navigate to={destination} />;
  }

  if (!!redirectToHostedUi) {
    document.location = redirect;
    return <div>Redirecting...</div>;
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-md-4">
          <form id="loginform" onSubmit={loginSubmit}>
            {showError && (
              <Alert
                variant="danger"
                onClose={() => setShowError(false)}
                dismissible
              >
                <p>Incorrect Email or Password!</p>
              </Alert>
            )}
            <div className="form-group">
              <label>Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                className="form-control"
                id="passowrd"
                placeholder="Password"
                onChange={(e) =>
                  setUser({
                    ...user,
                    password: e.target.value,
                  })
                }
              />
            </div>
            <button type="submit" className="btn submit btn-primary">
              Submit
            </button>
            <br />
            <button
              type="button"
              className="btn btn-primary mt-3"
              onClick={() => (location.href = redirect)}
            >
              Go to Cognito Hosted UI
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
