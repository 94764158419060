export enum ApplicationStatus {
  IN_PROGRESS = "In Progress",
  REJECTED = "Rejected",
  COMPLETED = "Completed",
  REMOVED = "Removed",
  CREDIT_AND_ID_RETURNED = "Credit And ID Returned",
  LEASE_PENDING = "Lease Pending",
  LEASE_SIGNED = "Lease Signed",
  CREADIT_AND_ID_PENDING = "Credit And ID Pending",
  CANCELLED = "Cancelled",
  APPROVED = "Approved",
  CLOSED = "Closed",
  INVITE_SENT = "Invite Sent",
  ACTION_REQUIRED = "Action Required",
  APPLICATION_COMPLETE = "Application Complete",
  EXPIRED = "Expired",
}
