import React, { useState, useEffect } from "react";
import { ApplicationStatusText } from "../../types/Application";
import { isClockExpired } from "../../utils/HoldClock";
import StatusTag from "./StatusTag";

interface Props {
  remainingTime?: number | undefined | null;
  status: ApplicationStatusText | "Expired";
}

const HoldClockStatus = ({ remainingTime, ...props }: Props) => {
  const [status, setStatus] = useState(props.status);

  useEffect(() => {
    if (
      typeof remainingTime !== "number" ||
      (typeof remainingTime === "number" && isClockExpired(remainingTime))
    )
      return;
    const timerId = setTimeout(() => {
      setStatus("Expired");
    }, remainingTime);
    return () => {
      return clearTimeout(timerId);
    };
  }, [remainingTime]);
  return <StatusTag name={status} />;
};

export default HoldClockStatus;
