import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { useNavigate } from "react-router";
import { completeAndRemoveApplication } from "../../services/AdminDashService";

const CompleteApplicationModal = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notification = useAppSelector(
    (state) => state.applications.notification
  );

  let {
    selectedApplicant,
    selectedApplication,
    onCancel,
    showModal,
    setShowModal,
  } = props;
  const cancelAction = (e) => {
    onCancel(e);
  };
  useEffect(() => {
    if (
      notification &&
      notification.type === "INFO" &&
      notification.message?.endsWith("has been successfully finished")
    ) {
      navigate("/AdminDashboard/Applications");
    } else if (
      notification &&
      notification.type === "ERROR" &&
      notification.title === "Unable to complete Application"
    ) {
      onCancel();
      setShowModal(false);
    }
  }, [notification]);

  const completeApplication = () => {
    dispatch(
      completeAndRemoveApplication(
        selectedApplication.applicationId,
        selectedApplication.unitId
      )
    );
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
    >
      <Modal.Header>
        <Modal.Title>
          <span className="cancel-icon">!</span> Finish Application
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="details">
          <div className="row">
            <div className="col form">
              <label>Application:</label>
            </div>
            <div className="col">
              <div>
                {selectedApplication && selectedApplication.communityName}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col form">
              <label>Primary Applicant:</label>
            </div>
            <div className="col">
              <div>
                {selectedApplicant &&
                  `${selectedApplicant.firstName}${
                    selectedApplicant.middleName
                      ? " " + selectedApplicant.middleName + " "
                      : " "
                  }${selectedApplicant.lastName}`}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={(e) => cancelAction(e)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => completeApplication()}>
          Finish Application
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompleteApplicationModal;
