import { Collapse, Table } from "antd";
import { useEffect, useState } from "react";
import { convertToLocalAndFormatDate, formatTime } from "../../utils/Utils";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import {
  Application,
  ApplicationStatusId,
  ApplicationStatusText,
  ReduxApplication,
} from "../../types/Application";
import { LeaseEventId } from "../../types/Application";
import GenerateLeaseModal from "./GenerateLeaseModal";
import StatusTag from "../Shared/StatusTag";
import { postLease } from "../../services/AdminDashService";
import LeaseAddendumModal from "./LeaseAddendumModal";
import { saveAddendumAnswers } from "src/services/AdminDashService";
import { setNotification } from "src/store/features/applications/applicationsSlice";
import { QuestionType } from "src/types/Addendum";
import { getAddendumAssociateAction } from "src/utils/Addendum";
import RentStabilizationModal from "./RentStabilizationModal";
require("./LeaseProcess.scss");

function getGeneratedDate(leaseGeneratedDate) {
  return leaseGeneratedDate
    ? `${formatTime(leaseGeneratedDate)}, ${convertToLocalAndFormatDate(
        leaseGeneratedDate
      )}`
    : "";
}

interface LeaseProcessProps {
  application: ReduxApplication;
  isRentStabilizationCommunity: boolean;
}

interface ConfirmCancelAddendumModalProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}

const ConfirmCancelAddendumModal = ({
  open,
  onClose,
  onCancel,
}: ConfirmCancelAddendumModalProps): JSX.Element => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
    <Paper>
      <DialogTitle>
        <Typography>Cancel Lease Addendum Responses?</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Are you sure you want to discard your responses? Your answers will not
          be saved.
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button
          onClick={() => onClose()}
          variant="text"
          color="inherit"
          data-testid="confirm-cancel-addendum-modal-close-button"
        >
          Continue Working
        </Button>
        <Button
          onClick={() => {
            onCancel();
            onClose();
          }}
          variant="text"
          color="error"
          data-testid="confirm-cancel-addendum-modal-discard-button"
        >
          Discard
        </Button>
      </DialogActions>
    </Paper>
  </Dialog>
);

const LeaseProcess = ({
  application,
  isRentStabilizationCommunity,
}: LeaseProcessProps) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(
    (state) => state.applications.notification
  );
  const { parking, addendums } = application ?? {};
  const [showGenerateLeaseModal, setShowGenerateLeaseModal] =
    useState<boolean>(false);
  const [showLeaseAddendumModal, setShowLeaseAddendumModal] =
    useState<boolean>(false);
  const [loadingAddendumSubmit, setLoadingAddendumSubmit] =
    useState<boolean>(false);
  const [showAddendumCancelModal, setShowAddendumCancelModal] =
    useState<boolean>(false);
  const [showRentStabilizationModal, setShowRentStabilizationModal] =
    useState<boolean>(false);
  const addendumForms = addendums?.forms ?? [];

  const validLeaseStatus = [
    ApplicationStatusId.LeaseReady,
    ApplicationStatusId.LeasePending,
    ApplicationStatusId.LeaseSigned,
    ApplicationStatusId.LeaseExpired,
    ApplicationStatusId.LeaseDeclined,
  ];

  useEffect(() => {
    const { origin } = notification ?? {};
    if (origin === "postLease") {
      window.scrollTo(0, 0);
    }
    if (
      notification &&
      notification.message &&
      notification.type === "INFO" &&
      notification.message.endsWith(
        "Success! Lease addendum answers submitted"
      ) &&
      notification.origin === "saveAddendumAnswers"
    ) {
      setShowRentStabilizationModal(false);
      setShowLeaseAddendumModal(false);
      setLoadingAddendumSubmit(false);
    }
  }, [notification]);

  if (!validLeaseStatus.includes(application?.applicationStatusId)) {
    return null;
  }
  const { Panel } = Collapse;

  const lastEvent = application?.lease?.leaseEvents?.[0] ?? null;
  const leaseGeneratedDate =
    application?.leaseGeneratedDate ||
    (application?.applicationStatus === ApplicationStatusText.LEASE_READY &&
      lastEvent?.leaseEventId === LeaseEventId.LeaseGenerated &&
      lastEvent?.createdDate) ||
    "";

  const isAddendumsEnabled = addendumForms?.length > 0;
  const hasAddendumResponse = addendumForms.some(
    (addendumForm) => addendumForm.responses?.length
  );

  // for each addendum form:
  //   if no responses, render "None"
  //   for each response:
  //     if single-line or multi-line, render each response as a sub-row
  //     if multi-select or single-select, render each answerText
  const addendumTableRows = !hasAddendumResponse
    ? []
    : addendumForms.map((form) => ({
        addendum: form.formName,
        ["resident-response"]: !form?.responses?.length
          ? "None"
          : form.responses.map((response) => {
              const question = form?.questions?.find((question) =>
                question.answers?.map((a) => a.id).includes(response.answerId)
              );
              if (!question) return null;
              const answer = question.answers?.find(
                (answer) => answer.id === response.answerId
              );
              if (!answer) return null;
              switch (question.questionType) {
                case QuestionType.SingleLine:
                case QuestionType.MultiLine:
                  return (
                    <div key={`table-answer-${answer.id}`}>
                      {response.answerValue}
                    </div>
                  );
                case QuestionType.SingleSelect:
                  return (
                    <div key={`table-answer-${answer.id}`}>
                      {answer.answerText}
                    </div>
                  );
                case QuestionType.MultiSelect:
                  return (
                    <div key={`table-answer-${answer.id}`}>
                      {answer.answerText}
                    </div>
                  );
                default:
                  return null;
              }
            }),
        ["associate-action"]: !form?.responses?.length
          ? "N/A"
          : getAddendumAssociateAction(form.responses),
      }));

  const renderStatusTag = (): JSX.Element => {
    let name: string = application?.applicationStatus;
    // if in lease-ready state and addendums is enabled, render Addendum status
    if (name === ApplicationStatusText.LEASE_READY && isAddendumsEnabled) {
      // if parking has been selected, render Pending Addendum Selection
      if (
        parking != null &&
        !parking.optInApplicantId &&
        !parking.optOutApplicantId
      ) {
        name = "Pending Addendums";
      } else if (!hasAddendumResponse) {
        name = "Pending Addendum Response";
      }
    }
    return <StatusTag name={name} />;
  };

  return (
    <div id="avb-lease-process">
      <Collapse defaultActiveKey={"2"} bordered={false}>
        <Panel header={`LEASE GENERATION PROCESS`} key="2">
          <div className="row">
            <div className="blue-card col-sm-12 col-md-4">
              <h3>Lease Generation Process</h3>
              <p>LEASE STATUS</p>
              {application && application.applicationStatus && (
                <div className="tagPlaceHolder mb-1">{renderStatusTag()}</div>
              )}
              {leaseGeneratedDate && (
                <p>
                  LEASE GENERATED TIME
                  <span>{getGeneratedDate(leaseGeneratedDate)}</span>
                </p>
              )}
            </div>
            {isAddendumsEnabled && (
              <div className="addendum-table_wrapper d-flex flex-column col-sm-12 justify-content-center col-md-8">
                <Table
                  id="addendum-information"
                  dataSource={addendumTableRows}
                  columns={[
                    {
                      title: "Addendum",
                      dataIndex: "addendum",
                      key: "addendum",
                    },
                    {
                      title: "Resident response",
                      dataIndex: "resident-response",
                      key: "resident-response",
                    },
                    {
                      title: "Associate action",
                      dataIndex: "associate-action",
                      key: "associate-action",
                    },
                  ]}
                  pagination={false}
                  rowKey="addendum"
                />
                {!hasAddendumResponse && (
                  <div className="d-flex justify-content-center mt-4">
                    <Button
                      data-testid="review-addendum-button"
                      variant="dark"
                      onClick={() => setShowLeaseAddendumModal(true)}
                      disabled={!addendumForms?.length}
                    >
                      Review Lease Addendums
                    </Button>
                  </div>
                )}
              </div>
            )}
            {!!application?.lease && (
              <div className="lease-button_container">
                <LeaseButton
                  application={application}
                  onClick={() =>
                    isRentStabilizationCommunity
                      ? setShowRentStabilizationModal(true)
                      : setShowGenerateLeaseModal(true)
                  }
                />
              </div>
            )}
          </div>
        </Panel>
      </Collapse>
      <GenerateLeaseModal
        selectedApplication={application}
        onClose={() => setShowGenerateLeaseModal(false)}
        show={showGenerateLeaseModal}
      />
      <RentStabilizationModal
        open={showRentStabilizationModal}
        onClose={() => setShowRentStabilizationModal(false)}
        selectedApplication={application}
      />

      {isAddendumsEnabled && (
        <>
          <LeaseAddendumModal
            open={showLeaseAddendumModal}
            addendums={addendumForms}
            onSubmit={(isValidForm, formValues) => {
              if (isValidForm && formValues) {
                dispatch(
                  saveAddendumAnswers(application.applicationId, formValues)
                );
                setLoadingAddendumSubmit(true);
              } else {
                dispatch(
                  setNotification({
                    type: "ERROR",
                    message: "Error submitting answers, please try again later",
                  })
                );
              }
            }}
            onClose={() => setShowAddendumCancelModal(true)}
            loading={loadingAddendumSubmit}
          />
          <ConfirmCancelAddendumModal
            open={showAddendumCancelModal}
            onClose={() => setShowAddendumCancelModal(false)}
            onCancel={() => setShowLeaseAddendumModal(false)}
          />
        </>
      )}
    </div>
  );
};

interface LeaseButtonProps {
  application: Application;
  onClick: () => void;
}
const LeaseButton = ({ application, onClick }: LeaseButtonProps) => {
  const { applicationId, applicationStatus, lease } = application;
  if (applicationStatus == null || applicationId == null) {
    return null;
  }

  const dispatch = useAppDispatch();

  const resendLease = () => {
    dispatch(postLease(applicationId, LeaseEventId.LeaseResent));
  };

  const viewLease = () => {
    const lastEvent = lease?.leaseEvents?.[0] ?? null;
    if (!!lastEvent) {
      const { dsDocPath = null } = lastEvent;
      dsDocPath && window.open(dsDocPath, "_blank");
    }
  };

  if (
    lease?.canGenerateLease ||
    applicationStatus == ApplicationStatusText.LEASE_READY
  ) {
    return (
      <Button
        data-testid="generate-lease-button"
        variant="dark"
        onClick={onClick}
        disabled={!lease?.canGenerateLease}
      >
        Generate Lease in DocuSign
      </Button>
    );
  } else if (lease?.canResendLease) {
    return (
      <Button
        data-testid="resend-lease-button"
        variant="outline-dark"
        onClick={resendLease}
        style={{
          marginRight: "15px",
        }}
      >
        Resend Email
      </Button>
    );
  }

  if (applicationStatus === ApplicationStatusText.LEASE_SIGNED) {
    return (
      <Button
        variant="dark"
        onClick={viewLease}
        data-testid="view-lease-button"
      >
        View Lease
      </Button>
    );
  }

  return null;
};

export default LeaseProcess;
