const getApplication = (applicationId) => {
  const myStorage = window.localStorage;
  let application = myStorage.getItem(applicationId);
  return application != null
    ? JSON.parse(application)
    : { application: { applicationId }, helpRequests: {} };
};

const setApplicationInLocal = (application, helpRequests) => {
  const myStorage = window.localStorage;
  myStorage.setItem(
    application.applicationId,
    JSON.stringify({
      application,
      helpRequests,
    })
  );
};

const removeApplication = (application) => {
  const myStorage = window.localStorage;
  myStorage.removeItem(application.applicationId);
};

const updateLocalStorageApps = (applications) => {
  const myStorage = window.localStorage;
  const appIds = Object.keys(myStorage);
  appIds.forEach((appId) => {
    const app = applications.find(
      (app) => app.applicationId.toString() === appId
    );
    const data = JSON.stringify({
      application: app,
    });

    if (!!app) {
      myStorage.setItem(appId, data);
      // broadcast update
      window.localStorage.setItem("APPLICATION_REFRESHED", appId);
    }
  });
};

const LocalStorageService = {
  getApplication: getApplication,
  setApplicationInLocal: setApplicationInLocal,
  updateLocalStorageApps: updateLocalStorageApps,
  removeApplication: removeApplication,
};

export default LocalStorageService;
