const Action = ({ action, actionClick, selected }) => {
  return (
    <div className="outer-action-container">
      <div
        className={`action-container ${selected ? "selected" : ""}`}
        onClick={() => actionClick(action.id)}
      >
        <div className="value">{action.value}</div>
        <div className="text display-linebreak">{action.text}</div>
      </div>
    </div>
  );
};

export default Action;
