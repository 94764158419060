import { Application } from "src/types/Application";

export function computeReadyToFinish(data: Application) {
  const applicationStatus = data.applicationStatus?.toUpperCase();
  const cancelled = data && applicationStatus === "CANCELLED";

  const completed = data
    ? ["LEASE SIGNED", "LEASE EXPIRED", "LEASE DECLINED"].includes(
        applicationStatus
      )
    : false;

  const readyToFinishCount = cancelled || completed ? 1 : 0;

  return readyToFinishCount;
}
