/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-multi-assign */
import React, { FormEvent, useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  DialogActions,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { IMaskInput } from "react-imask";
import {
  AddendumsForm,
  AddendumsQuestion,
  QuestionType,
  AddendumsPostAddendums,
  AddendumsResponse,
  AddendumsAnswerId,
} from "src/types/Addendum";

// some boolean answers must be sent with a value "no"
const negateAnswerIds: number[] = [2, 8, 10, 17];

interface Props {
  open: boolean;
  onClose: () => void;
  addendums: AddendumsForm[];
  onSubmit: (
    isValidForms: boolean,
    formValues: AddendumsPostAddendums[] | null
  ) => void;
  loading: boolean;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const PhoneNumberMask = React.forwardRef<HTMLInputElement, CustomProps>(
  function PhoneNumberMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
        minLength={14}
        maxLength={14}
      />
    );
  }
);

const renderQuestions = (question: AddendumsQuestion): JSX.Element =>
  question.questionType === QuestionType.SingleLine ? (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {question.answers?.map((answer) => (
        <Grid item md={12} xs={12} key={`answer-${answer.id}`} mt={1}>
          <TextField
            onChange={(e) => e.target.closest?.("form")?.reportValidity()}
            label={answer.answerText}
            name={answer.id?.toString()}
            variant="outlined"
            fullWidth
            required={answer.isRequired}
            type={
              answer.id === AddendumsAnswerId.EmContactEmail ? "email" : "text"
            }
            placeholder={
              answer.id === AddendumsAnswerId.EmContactPhone
                ? "(#00) 000-0000"
                : undefined
            }
            focused
            sx={{
              "& label.Mui-focused": {
                color: "black",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                  borderWidth: "1px",
                },
                "input:invalid + fieldset": {
                  borderColor: "red",
                },
              },
            }}
            InputProps={{
              inputComponent:
                answer.id === AddendumsAnswerId.EmContactPhone
                  ? (PhoneNumberMask as any)
                  : undefined,
            }}
            data-testid={`${answer.id}-textfield`}
          />
        </Grid>
      ))}
    </Grid>
  ) : (
    <FormControl required={question.isRequired} key={question.id}>
      <FormLabel>{question.questionText}</FormLabel>
      {question.questionType === QuestionType.SingleSelect ? (
        <RadioGroup
          aria-labelledby={`${question.id}-answer`}
          name="radio-buttons-group"
          data-testid={`${question.id}-radio`}
        >
          {question.answers?.map((answer) => (
            <FormControlLabel
              key={`answer-${answer.id}`}
              required={answer.isRequired}
              control={
                <Radio name={question.id?.toString()} value={answer.id} />
              }
              label={answer.answerText}
              value={answer.id}
              data-testid={`${answer.id}-answer-radio`}
            />
          ))}
        </RadioGroup>
      ) : (
        <FormGroup>
          {question.answers?.map((answer) => (
            <FormControlLabel
              key={`answer-${answer.id}`}
              control={
                <Checkbox name={question.id?.toString()} value={answer.id} />
              }
              label={answer.answerText}
              value={answer.id}
              data-testid={`${answer.id}-answer-checkbox`}
            />
          ))}
        </FormGroup>
      )}
    </FormControl>
  );

const parseToHtml = (content: string): JSX.Element => (
  <Typography paragraph sx={{ whiteSpace: "pre-line" }} p={0}>
    <span dangerouslySetInnerHTML={{ __html: content }} />
  </Typography>
);

const renderAddendumAccordion = (
  addendum: AddendumsForm,
  refFn: (element: HTMLFormElement, questionId: number) => void,
  onFormChange: (event: FormEvent<HTMLElement>) => void,
  expanded: boolean
): JSX.Element => (
  <Accordion key={addendum.formName} defaultExpanded={expanded}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="questions-panel"
      id="questions-header"
    >
      <Container>
        <Typography>{addendum.formName}</Typography>
      </Container>
    </AccordionSummary>
    <Container>
      <AccordionDetails>
        {addendum.description && parseToHtml(addendum.description)}
        {addendum.questions?.map((question) => (
          <form
            key={`question-form-${question.id}`}
            name={question.id?.toString()}
            onChange={onFormChange}
            onSubmit={(e) => e.preventDefault()}
            ref={(el: HTMLFormElement) => {
              if (question.id) refFn(el, question.id);
            }}
            data-min={
              question.questionType === QuestionType.SingleLine
                ? null
                : question.minAnswers
            }
          >
            {renderQuestions(question)}
          </form>
        ))}
        <FormHelperText>{addendum.disclaimer}</FormHelperText>
      </AccordionDetails>
    </Container>
  </Accordion>
);

interface FormRefs {
  [formName: string]: {
    [questionId: string]: HTMLFormElement;
  };
}

const LeaseAddendumModal = ({
  open,
  onClose,
  addendums,
  onSubmit,
  loading,
}: Props): JSX.Element => {
  const [isValidForms, setIsValidForms] = useState<boolean>(false);
  const formsRef = useRef<FormRefs>({});

  const getAllForms = (forms: FormRefs): HTMLFormElement[] =>
    Object.values(forms).reduce(
      (formsArr: HTMLFormElement[], obj) => [
        ...formsArr,
        ...Object.values(obj),
      ],
      []
    );

  const validateAddendumForms = (): void => {
    let isValid = false;
    if (formsRef.current) {
      const allForms = getAllForms(formsRef.current);
      isValid = !allForms.filter((questionForm) => {
        const minAnswer = parseInt(
          questionForm.getAttribute("data-min") ?? "0",
          10
        );
        return (
          !questionForm ||
          !questionForm.checkValidity() ||
          (minAnswer &&
            [...(questionForm.elements as any)].filter(
              (input: HTMLInputElement) => input.checked
            ).length < minAnswer)
        );
      }).length;
    }
    setIsValidForms(isValid);
  };

  // HTML form needs ref and name and fields have to contain name and value
  const getAddendumFormsData = (): AddendumsPostAddendums[] | null => {
    if (formsRef.current) {
      return Object.entries(formsRef.current)
        .map(([formName, questionRefs]) => {
          const responses = Object.values(questionRefs).reduce(
            (accum: AddendumsResponse[], form) => {
              const formInputs = Array.from(form.elements).filter(
                (el) =>
                  el.nodeName.toLowerCase() === "input" &&
                  el.getAttribute("name")
              );
              const answers = formInputs
                .filter((input) => {
                  const inputType = (
                    input as HTMLInputElement
                  ).type.toLowerCase();
                  return (
                    inputType === "text" ||
                    inputType === "email" ||
                    (input as HTMLInputElement).checked
                  );
                })
                .map((input) => {
                  const inputType = (
                    input as HTMLInputElement
                  ).type.toLowerCase();
                  let answerId = 0;
                  let answerValue = "";
                  if (inputType === "radio" || inputType === "checkbox") {
                    answerId = parseInt((input as HTMLInputElement).value, 10);
                    answerValue = negateAnswerIds.includes(answerId)
                      ? "no"
                      : "yes";
                  } else if (inputType === "text" || inputType === "email") {
                    answerId = parseInt(input.getAttribute("name") ?? "0", 10);
                    answerValue = (input as HTMLInputElement).value;
                    if (answerId === AddendumsAnswerId.EmContactPhone) {
                      answerValue = answerValue.replace(/[^0-9]/g, "");
                    }
                  }
                  return {
                    answerId,
                    answerValue,
                  };
                });
              return [...accum, ...answers];
            },
            []
          );
          return responses.length
            ? { formName, responses }
            : (null as any as AddendumsPostAddendums);
        })
        .filter(Boolean);
    }
    return null;
  };

  useEffect(() => {
    formsRef.current = formsRef.current = {};
    validateAddendumForms();
  }, [addendums, open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        data-testid="lease-addendum-modal"
      >
        <Paper>
          <DialogTitle>Review lease addendums</DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText mb={3} fontSize={17}>
              The applicant needs to provide some additional information before
              you can generate their lease. The applicant can fill out this
              information by logging into the applicant dashboard and clicking
              on&nbsp;
              <b>“Review Lease Addendums,”</b> or you can contact the applicant
              and make these selections on their behalf. Only one applicant
              needs to make these selections. Once submitted, this
              information&nbsp;
              <b>cannot</b> be altered.
            </DialogContentText>
            {addendums.map((addendum, index) =>
              renderAddendumAccordion(
                addendum,
                (el, questionId) => {
                  if (el && addendum.formName) {
                    formsRef.current[addendum.formName] = formsRef.current[
                      addendum.formName
                    ]
                      ? formsRef.current[addendum.formName]
                      : {};
                    formsRef.current[addendum.formName][questionId] = el;
                    validateAddendumForms();
                  }
                },
                () => {
                  validateAddendumForms();
                },
                !index
              )
            )}
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Button onClick={() => onClose()} variant="text" color="inherit">
              Cancel
            </Button>
            <Button
              disabled={!isValidForms}
              onClick={() => {
                if (isValidForms) {
                  const data = getAddendumFormsData();
                  onSubmit(isValidForms, data);
                }
              }}
              variant="text"
              color="success"
              data-testid="submit-lease-addendum-form"
            >
              Submit
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) =>
            Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default LeaseAddendumModal;
