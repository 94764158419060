require("./ApplicantInformationModal.scss");
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import CloseButton from "react-bootstrap/CloseButton";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { EditOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { Input, DatePicker, Space } from "antd";
import moment from "moment";
const { TextArea } = Input;

import { updateApplicantById } from "../../services/AdminDashService";
import { getApplicationById } from "../../services/AdminDashService";

import {
  convertToLocalAndFormatDate,
  formatDate,
  formatTime,
  formatPhone,
  isApplicationEditable,
} from "../../utils/Utils";

const ApplicantInformationModal = (props) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(
    (state) => state.applications.notification
  );
  const applicationStatus = useAppSelector(
    (state) => state.applications.applicationStatus
  );
  const applicationTermsAndConditions = useAppSelector(
    (state) => state.applications.applicationTermsAndConditions
  );
  const applicant = props.applicant;
  const [editMode, setEditMode] = useState(false);
  const [firstName, setFirstName] = useState(applicant.firstName);
  const [middleName, setMiddleName] = useState(applicant.middleName);
  const [lastName, setLastName] = useState(applicant.lastName);
  const [dob, setDob] = useState(applicant.dob);
  const [address1, setAddress1] = useState(applicant.address1);
  const [address2, setAddress2] = useState(applicant.address2);
  const [city, setCity] = useState(applicant.city);
  const [state, setState] = useState(applicant.state);
  const [zip, setZip] = useState(applicant.zip);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const firstMiddleLastNameLimit = 20;
  const addressLimit = 50;
  const cityLimit = 28;
  const stateLimit = 2;
  const zipLimit = 9;
  const dateFormat = "MM/DD/YYYY";
  const isEditable = isApplicationEditable(applicant);
  const requiredFirstNameError = "First Name is required";
  const requiredLastNameError = "Last Name is required";
  const requiredDOBError = "Birth date is required";
  const requiredAddress1Error = "Address 1 is required";
  const requiredCityError = "City is required";
  const requiredStateError = "State is required";
  const requiredZipError = "Zip is required";

  const pushError = (error) => {
    const errIndex = errorMessage.indexOf(error);
    if (errIndex == -1) {
      setErrorMessage([...errorMessage, error]);
    }
  };

  const popError = (error) => {
    const errIndex = errorMessage.indexOf(error);
    if (errIndex > -1) {
      errorMessage.splice(errIndex, 1);
      setErrorMessage(errorMessage);
    }
  };

  const handleFirstNameChange = (e) => {
    if (isNullOrEmpty(e.target.value)) {
      pushError(requiredFirstNameError);
      setFirstName(null);
    } else {
      popError(requiredFirstNameError);
      setFirstName(e.target.value);
    }
  };

  const handleMiddleNameChange = (e) => {
    if (isNullOrEmpty(e.target.value)) {
      setMiddleName(null);
    } else {
      setMiddleName(e.target.value);
    }
  };

  const handleLastNameChange = (e) => {
    if (isNullOrEmpty(e.target.value)) {
      pushError(requiredLastNameError);
      setLastName(null);
    } else {
      popError(requiredLastNameError);
      setLastName(e.target.value);
    }
  };

  const handleDobChange = (date) => {
    if (date == null) {
      pushError(requiredDOBError);
      setDob(null);
    } else {
      popError(requiredDOBError);
      setDob(date.toDate());
    }
  };
  const handleAddress1Change = (e) => {
    if (isNullOrEmpty(e.target.value)) {
      pushError(requiredAddress1Error);
      setAddress1(null);
    } else {
      popError(requiredAddress1Error);
      setAddress1(e.target.value);
    }
  };

  const handleAddress2Change = (e) => setAddress2(e.target.value);
  const handleCityChange = (e) => {
    if (isNullOrEmpty(e.target.value)) {
      pushError(requiredCityError);
      setCity(null);
    } else {
      popError(requiredCityError);
      setCity(e.target.value);
    }
  };

  const handleStateChange = (e) => {
    if (isNullOrEmpty(e.target.value)) {
      pushError(requiredStateError);
      setState(null);
    } else {
      popError(requiredStateError);
      setState(e.target.value);
    }
  };

  const handleZipChange = (e) => {
    if (isNullOrEmpty(e.target.value)) {
      pushError(requiredZipError);
      setZip(null);
    } else {
      popError(requiredZipError);
      setZip(e.target.value);
    }
  };

  const startEditMode = () => {
    setEditMode(true);
    setErrorMessage([]);
  };

  const cancelEditMode = () => {
    setFirstName(props.applicant.firstName);
    setMiddleName(props.applicant.middleName);
    setLastName(props.applicant.lastName);
    setDob(new Date(props.applicant.dob));
    setAddress1(props.applicant.address1);
    setAddress2(props.applicant.address2);
    setCity(props.applicant.city);
    setState(props.applicant.state);
    setZip(props.applicant.zip);
    setEditMode(false);
    setErrorMessage([]);
  };

  const isNullOrEmpty = (str) => {
    return !str || str.length === 0;
  };

  const validateApplicantInfo = () => {
    var errorArr: string[] = [];
    if (isNullOrEmpty(firstName)) {
      errorArr.push(requiredFirstNameError);
    }

    if (isNullOrEmpty(lastName)) {
      errorArr.push(requiredLastNameError);
    }

    if (isNullOrEmpty(dob)) {
      errorArr.push(requiredDOBError);
    }

    if (isNullOrEmpty(address1)) {
      errorArr.push(requiredAddress1Error);
    }

    if (isNullOrEmpty(city)) {
      errorArr.push(requiredCityError);
    }

    if (isNullOrEmpty(state)) {
      errorArr.push(requiredStateError);
    }

    if (isNullOrEmpty(zip)) {
      errorArr.push(requiredZipError);
    }

    return errorArr;
  };

  const saveApplicantInfo = async () => {
    const errorArr = validateApplicantInfo();
    if (errorArr.length > 0) {
      setErrorMessage(errorArr);
      setDisableSaveButton(false);
      return;
    }

    setErrorMessage([]);
    setDisableSaveButton(true);
    const body = {
      firstName,
      middleName,
      lastName,
      dob,
      address1,
      address2,
      city,
      state,
      zip,
      emailAddress: props.applicant.emailAddress,
      nonUSPhone: props.applicant.nonUSPhone,
      isSSN: props.applicant.isSSN,
      sMSPreference: props.applicant.sMSPreference,
      emailPreference: props.applicant.emailPreference,
      isMiddleName: props.applicant.isMiddleName,
      phoneNumberType: props.applicant.phoneNumberType,
      phoneNumber: props.applicant.phoneNumber,
    };
    dispatch(updateApplicantById(applicant.id, body));
  };

  const displayTermsAndConditions = (applicant) => {
    const hasContent =
      applicationTermsAndConditions && applicationTermsAndConditions.length;

    if (hasContent) {
      const termsAndConditions = applicationTermsAndConditions.filter(
        (x) => x.type === "terms_and_conditions"
      );
      return termsAndConditions.map((x) => {
        return (
          <tr key={x.id}>
            <td>
              <a target="_blank" rel="noreferrer" href={x.url}>
                Terms and Conditions, {applicant.firstName}
              </a>
            </td>
            <td>Accepted</td>
            <td>{convertToLocalAndFormatDate(x.createdAt)}</td>
            <td>{formatTime(x.createdAt)}</td>
          </tr>
        );
      });
    }

    return null;
  };

  useEffect(() => {
    setFirstName(props.applicant.firstName);
    setMiddleName(props.applicant.middleName);
    setLastName(props.applicant.lastName);
    setDob(new Date(props.applicant.dob));
    setAddress1(props.applicant.address1);
    setAddress2(props.applicant.address2);
    setCity(props.applicant.city);
    setState(props.applicant.state);
    setZip(props.applicant.zip);
  }, [props.applicant]);

  useEffect(() => {
    setDisableSaveButton(false);
    if (
      notification &&
      notification.type === "INFO" &&
      notification.title == "Update Applicant Info"
    ) {
      dispatch(getApplicationById(props.applicant.applicationId));
      setEditMode(false);
      setErrorMessage([]);
      props.onHide();
    } else if (
      notification &&
      notification.type === "VALIDATION_ERROR" &&
      notification.title == "Update Applicant Info"
    ) {
      var errorArr: string[] = [];
      const errorObj = JSON.parse(notification?.message ?? "{}");
      for (const prop in errorObj) {
        errorObj[prop].forEach((errMessage) => {
          errorArr.push(`${prop}: ${errMessage}`);
        });
      }

      if (errorArr.length > 0) {
        setErrorMessage(errorArr);
      }
    }
  }, [notification]);

  return (
    <div id="avb-applicant-information">
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={editMode ? "static" : true}
        className="applicant-information-modal"
      >
        <Modal.Header className="header">
          <Modal.Title className="title">
            {isEditable ? "View / Edit" : "View"} Adult Application
          </Modal.Title>
          {!editMode && (
            <>
              {isEditable && (
                <Button
                  title="Edit Applicant"
                  variant="light"
                  className="edit-button"
                  size="lg"
                  onClick={startEditMode}
                >
                  <EditOutlined />
                </Button>
              )}
              <CloseButton onClick={props.onHide} />
            </>
          )}
          {editMode && (
            <>
              <Button
                variant="light"
                size="sm"
                disabled={disableSaveButton}
                className="cancel-button"
                onClick={cancelEditMode}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                disabled={disableSaveButton}
                className="save-button"
                onClick={saveApplicantInfo}
              >
                {disableSaveButton ? "Saving..." : "Save"}
              </Button>
            </>
          )}
        </Modal.Header>

        <Modal.Body>
          {!isEditable && (
            <Alert variant="secondary" className="pending-application">
              <span>Pending Applicant Information</span>
            </Alert>
          )}
          <h3>
            <span>1</span>Applicant Information
          </h3>
          {errorMessage.map((e) => (
            <Alert variant="danger" key={e}>
              {e}
            </Alert>
          ))}
          <div className="details">
            <h4>Personal Information</h4>
            <div className="row">
              <div className="first-name col required">
                <label>First Name</label>
                <div id="avb-applicant-info-first-name">
                  {editMode ? (
                    <Input
                      type="text"
                      value={firstName}
                      onChange={handleFirstNameChange}
                      maxLength={firstMiddleLastNameLimit}
                      disabled={disableSaveButton}
                    />
                  ) : (
                    <>{applicant.firstName}</>
                  )}
                </div>
              </div>
              <div className="col required">
                <label>Middle Initial</label>
                <div id="avb-applicant-info-middle-initial">
                  {editMode ? (
                    <Input
                      type="text"
                      value={middleName}
                      onChange={handleMiddleNameChange}
                      maxLength={firstMiddleLastNameLimit}
                      disabled={disableSaveButton}
                    />
                  ) : (
                    <>{applicant.middleName}</>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col required">
                <label>Last Name</label>
                <div id="avb-applicant-info-last-name">
                  {editMode ? (
                    <Input
                      type="text"
                      value={lastName}
                      onChange={handleLastNameChange}
                      maxLength={firstMiddleLastNameLimit}
                      disabled={disableSaveButton}
                    />
                  ) : (
                    <>{applicant.lastName}</>
                  )}
                </div>
              </div>
              <div className="col">
                <label>Suffix</label>
                <div id="avb-applicant-info-suffix">{applicant.suffix}</div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label>Email Address</label>
                <div id="avb-applicant-info-email">
                  {applicant.emailAddress}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col required">
                <label>Phone Number Type</label>
                <div id="avb-applicant-info-phone-type">
                  {applicant.phoneNumberType}
                </div>
              </div>
              <div className="col required">
                <label>Phone Number</label>
                <div id="avb-applicant-info-phone-number">
                  {formatPhone(applicant.phoneNumber)}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col required">
                <label>Birthday</label>
                <div id="avb-applicant-info-birthday">
                  {editMode ? (
                    <Space direction="vertical">
                      <DatePicker
                        defaultValue={moment(formatDate(dob), dateFormat)}
                        format={dateFormat}
                        onChange={handleDobChange}
                        disabled={disableSaveButton}
                      />
                    </Space>
                  ) : (
                    <>{formatDate(applicant.dob)}</>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="details">
            <h4>Current Address</h4>
            <div className="row">
              <div className="col required">
                <label>Street Address 1</label>
                <div id="avb-applicant-info-street-address1">
                  {editMode ? (
                    <TextArea
                      rows={2}
                      value={address1}
                      onChange={handleAddress1Change}
                      maxLength={addressLimit}
                      disabled={disableSaveButton}
                    />
                  ) : (
                    <>{applicant.address1}</>
                  )}
                </div>
              </div>
              <div className="col">
                <label>Street Address 2</label>
                <div id="avb-applicant-info-street-address2">
                  {editMode ? (
                    <TextArea
                      rows={2}
                      value={address2}
                      onChange={handleAddress2Change}
                      maxLength={addressLimit}
                      disabled={disableSaveButton}
                    />
                  ) : (
                    <>{applicant.address2}</>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-7 required">
                <label>City</label>
                <div id="avb-applicant-info-city">
                  {editMode ? (
                    <Input
                      type="text"
                      value={city}
                      onChange={handleCityChange}
                      maxLength={cityLimit}
                      disabled={disableSaveButton}
                    />
                  ) : (
                    <>{applicant.city}</>
                  )}
                </div>
              </div>
              <div className="col-2 required">
                <label>State</label>
                <div id="avb-applicant-info-state">
                  {editMode ? (
                    <Input
                      type="text"
                      value={state}
                      onChange={handleStateChange}
                      maxLength={stateLimit}
                      disabled={disableSaveButton}
                    />
                  ) : (
                    <>{applicant.state}</>
                  )}
                </div>
              </div>
              <div className="col-3 required">
                <label>Zip</label>
                <div id="avb-applicant-info-zip">
                  {editMode ? (
                    <Input
                      type="text"
                      value={zip}
                      onChange={handleZipChange}
                      maxLength={zipLimit}
                      disabled={disableSaveButton}
                    />
                  ) : (
                    <>{applicant.zip}</>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="details">
            <h4>Income</h4>
            <div className="row">
              <div className="col required">
                <label>Annual Income</label>
                <div id="avb-applicant-info-annual-income">
                  ${applicant.annualIncome}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col required">
                <label>Employer</label>
                <div id="avb-applicant-info-employer">{applicant.employer}</div>
              </div>
              <div className="col">
                <label>Employer Phone</label>
                <div id="avb-applicant-info-employer-phone">
                  {applicant.employerPhone}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label>Employer Address 1</label>
                <div id="avb-applicant-info-employer-address1">
                  {applicant.employerAddress1}
                </div>
              </div>
              <div className="col">
                <label>Employer Address 2</label>
                <div id="avb-applicant-info-employer-address2">
                  {applicant.employerAddress2}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-7">
                <label>City</label>
                <div id="avb-applicant-info-employer-city">
                  {applicant.employerCity}
                </div>
              </div>
              <div className="col-2">
                <label>State</label>
                <div id="avb-applicant-info-employer-state">
                  {applicant.employerState}
                </div>
              </div>
              <div className="col-3">
                <label>Zip</label>
                <div id="avb-applicant-info-employer-zip">
                  {applicant.employerZip}
                </div>
              </div>
            </div>
          </div>
          <div className="details">
            <h4>Demographic Information</h4>
            <div className="row">
              <div className="col">
                <label>Gender</label>
                <div id="avb-applicant-info-gender">{applicant.gender}</div>
              </div>
            </div>
          </div>
          <h3>
            <span>2</span>Term &amp; Conditions
          </h3>
          <div
            id="avb-applicant-info-terms-conditions"
            className="details outer-row"
          >
            <table>
              <thead>
                <tr>
                  <th>File</th>
                  <th>Status</th>
                  <th>Date Accepted</th>
                  <th>Time Accepted</th>
                </tr>
              </thead>
              <tbody>{displayTermsAndConditions(applicant)}</tbody>
            </table>
          </div>
          <h3>
            <span>3</span>Payment
          </h3>
          <div className="details">
            {applicationStatus ? (
              <div
                id="avb-applicant-info-payment-status"
                className="row status"
              >
                {applicationStatus.paymentStatus === "Completed" ? (
                  <>
                    <label>
                      <span
                        className={
                          applicationStatus && applicationStatus.paymentStatus
                            ? applicationStatus.paymentStatus.toLowerCase()
                            : "in-process"
                        }
                      ></span>
                      {applicationStatus && applicationStatus.paymentStatus}
                    </label>
                    Applicant has paid the required amount.
                  </>
                ) : (
                  <label>
                    <span
                      className={
                        applicationStatus && applicationStatus.paymentStatus
                          ? applicationStatus.paymentStatus.toLowerCase()
                          : "in-process"
                      }
                    ></span>
                    {applicationStatus && applicationStatus.paymentStatus}
                  </label>
                )}

                {/* <label><span className={applicationStatus === '2'? 'complete' : 'in-process'}></span>{applicationStatus && applicationStatus.paymentStatus}</label> */}
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {editMode && (
            <>
              <Button
                variant="light"
                disabled={disableSaveButton}
                className="cancel-button"
                onClick={cancelEditMode}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={disableSaveButton}
                className="save-button"
                onClick={saveApplicantInfo}
              >
                {disableSaveButton ? "Saving..." : "Save"}
              </Button>
            </>
          )}
          {!editMode && (
            <>
              <Button variant="primary" onClick={props.onHide}>
                OK
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ApplicantInformationModal;
