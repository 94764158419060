const ID_TOKEN_KEY = "token";
const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const TOKEN_TYPE_KEY = "token_type";
const EXPIRES_IN_KEY = "expires_in";
const EMAIL_KEY = "email";
const LOGOUT_KEY = "avb-logout";

// used to contain the key of the credentials carrier aka. courier
const TRANSFER_KEY = "courier";

const isAuthenticated = () => {
  const token = sessionStorage.getItem(ID_TOKEN_KEY);
  return !!token;
};

const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
};

const decodeToken = (token) => {
  try {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const decodedToken = JSON.parse(jsonPayload);
    return decodedToken;
  } catch (err) {
    throw "Invalid token";
  }
};

const storeCredentials = (credentials) => {
  const { idToken, refreshToken, tokenType, expiresIn, accessToken } =
    credentials;
  const _refreshToken =
    sessionStorage.getItem(REFRESH_TOKEN_KEY) || refreshToken;

  const decodedToken = decodeToken(idToken);
  const { email } = decodedToken;
  sessionStorage.setItem(EMAIL_KEY, email);
  sessionStorage.setItem(ID_TOKEN_KEY, `Bearer ${idToken}`);
  sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  sessionStorage.setItem(REFRESH_TOKEN_KEY, _refreshToken);
  sessionStorage.setItem(TOKEN_TYPE_KEY, tokenType);
  sessionStorage.setItem(EXPIRES_IN_KEY, expiresIn);
};

const storeCredentialsFromCallback = (credentials) => {
  const { id_token, refresh_token, token_type, expires_in, access_token } =
    credentials;

  const decodedToken = decodeToken(id_token);
  sessionStorage.setItem(EMAIL_KEY, decodedToken["cognito:username"]);
  sessionStorage.setItem(ID_TOKEN_KEY, `Bearer ${id_token}`);
  sessionStorage.setItem(ACCESS_TOKEN_KEY, access_token);
  sessionStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
  sessionStorage.setItem(TOKEN_TYPE_KEY, token_type);
  sessionStorage.setItem(EXPIRES_IN_KEY, expires_in);
};

const fetchIdToken = () => {
  return sessionStorage.getItem(ID_TOKEN_KEY);
};

const fetchAccessToken = () => {
  return sessionStorage.getItem(ACCESS_TOKEN_KEY);
};

const fetchRefreshToken = () => {
  return sessionStorage.getItem(REFRESH_TOKEN_KEY);
};

/**
 * Helper function for fetching all credentials
 * in a base64 encoded form
 */
const fetchBase64Credentials = () => {
  const email = sessionStorage.getItem(EMAIL_KEY);
  const id_token = sessionStorage.getItem(ID_TOKEN_KEY);
  const access_token = sessionStorage.getItem(ACCESS_TOKEN_KEY);
  const refresh_token = sessionStorage.getItem(REFRESH_TOKEN_KEY);
  const token_type = sessionStorage.getItem(TOKEN_TYPE_KEY);
  const expires_in = sessionStorage.getItem(EXPIRES_IN_KEY);

  const obj = {
    email,
    id_token,
    access_token,
    refresh_token,
    token_type,
    expires_in,
  };
  const base64Encoded = btoa(JSON.stringify(obj));
  return base64Encoded;
};

/**
 * Helper function for fetching all credentials
 * in a base64 encoded form
 */
const storeBase64Credentials = (base64) => {
  const decoded = atob(base64);
  const obj = JSON.parse(decoded);
  const {
    email,
    id_token,
    access_token,
    refresh_token,
    token_type,
    expires_in,
  } = obj;

  sessionStorage.setItem(EMAIL_KEY, email);
  sessionStorage.setItem(ID_TOKEN_KEY, id_token);
  sessionStorage.setItem(ACCESS_TOKEN_KEY, access_token);
  sessionStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
  sessionStorage.setItem(TOKEN_TYPE_KEY, token_type);
  sessionStorage.setItem(EXPIRES_IN_KEY, expires_in);
};

/**
 * Helper function for transferring credentials to another tab
 * session to local
 */
const transferCredentials = () => {
  // check for authenticated uesr
  if (!isAuthenticated()) return;

  // fetch our base64 encoded credentials
  const encoded = fetchBase64Credentials();
  // generate a courier value that changes every time so other applications can't
  // possibly attempt to catch our credentials during the transfer to another tab
  const courier = btoa(JSON.stringify(new Date()));
  localStorage.setItem(TRANSFER_KEY, courier);
  // finally set the credentials
  localStorage.setItem(courier, encoded);
};

/**
 * Helper function for receiving credentials and removing from local storage
 * local to session and delete
 */
const receiveCredentials = () => {
  const courier = localStorage.getItem(TRANSFER_KEY);
  if (!courier) return;

  const encoded = localStorage.getItem(courier);
  if (!encoded) return;

  storeBase64Credentials(encoded);
  localStorage.removeItem(courier);
  localStorage.removeItem(TRANSFER_KEY);
};

const initCrossTabLogout = () => {
  window.onstorage = (event) => {
    if (event.key === LOGOUT_KEY) {
      console.log("Log out called across tabs");
      logout();
      // old reliable redirection
      window.location.href = "/";
    }
  };
};

const triggerCrossTabLogout = () => {
  localStorage.setItem(LOGOUT_KEY, "true");
};

export {
  fetchIdToken,
  fetchAccessToken,
  fetchRefreshToken,
  initCrossTabLogout,
  isAuthenticated,
  logout,
  storeCredentials,
  storeCredentialsFromCallback,
  receiveCredentials,
  transferCredentials,
  triggerCrossTabLogout,
};
