import { Button, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import { resetNotification } from "src/store/features/applications/applicationsSlice";

const WarningModal = () => {
  const notification = useAppSelector(
    (state) => state.applications.notification
  );
  const dispatch = useAppDispatch();
  const reset = () => dispatch(resetNotification());
  return (
    <Modal
      centered
      show={notification && notification.type === "ERROR"}
      className="warning-modal"
    >
      <Modal.Body>
        <div className="error-message">
          {notification && (
            <div>
              <h2>{notification.title}</h2>
              <p className="display-line-break">{notification.message}</p>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="btn-blue-grey" onClick={reset}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default WarningModal;
