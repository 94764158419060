import { CommunityFilter, GetFilterOptionsBody } from "src/types/FilterOptions";

interface Option {
  value: string;
  label: string;
  customValue?: string;
}

const hasMarketSelections = (userSelections) => {
  return userSelections.markets && userSelections.markets !== "";
};

const hasRegionSelections = (userSelections) => {
  return userSelections.regions && userSelections.regions !== "";
};

const formatOptions = (
  options,
  label: "region" | "market" | "community"
): Option[] => {
  return (
    options &&
    options.map((x) => {
      const option: Option = { value: x[`${label}Id`], label: x[label] };
      if (label === "community") {
        option.customValue = x.properties
          ?.map((property) => property.propertyId)
          .join(",");
      }
      return option;
    })
  );
};

const selections2Options = (
  filterOptions,
  type: "region" | "market" | "community"
) => {
  const mapType = {
    community: "communities",
    region: "regions",
    market: "markets",
  }; // Ids are singular but the original arrays are plural
  if (
    !filterOptions ||
    !filterOptions.userFilterSelections ||
    !filterOptions.userFilterSelections[mapType[type]]
  )
    return [];

  const selections = filterOptions.userFilterSelections[mapType[type]];
  if (selections === "") return [];
  const selectionsArr = selections.split(",");
  const sourceArr = filterOptions[mapType[type]];
  const filteredSource = sourceArr.filter((x) => {
    return selectionsArr.includes(x[`${type}Id`]);
  });
  return formatOptions(filteredSource, type);
};

const options2String = (input: any[]): string => {
  if (Array.isArray(input)) {
    const values = input.map((i) => i.value);
    return values.join(",");
  }
  return input;
};

/**
 * Helper function for converting filter selection array to string
 * Note: This also checks whether the selection is already an array
 */
const formatArrayToString = (input) => {
  if (Array.isArray(input)) {
    // extract values from the array
    const values = input.map((i) => i.value);
    // concatenate values for POST /filter-options
    return values.join(",");
  }
  return input;
};

const getCommunitiesFromMarketIds = (
  communities: CommunityFilter[],
  selectedMarketIds
): CommunityFilter[] => {
  const marketIdArray =
    typeof selectedMarketIds === "string"
      ? selectedMarketIds.split(",")
      : selectedMarketIds;
  return communities.filter((x) => {
    return marketIdArray.includes(x.marketId);
  });
};

const getMarketIdsFromRegionIds = (selectedRegionIds, markets) => {
  const regionIdArray =
    typeof selectedRegionIds === "string"
      ? selectedRegionIds.split(",")
      : selectedRegionIds;
  const selectedMarkets = markets.filter((x) => {
    return regionIdArray.includes(x.regionId);
  });
  return selectedMarkets.map((x) => x.marketId);
};

const getMarketsFromRegionIds = (selectedRegionIds, markets) => {
  const regionIdArray = selectedRegionIds.split(",");
  return markets.filter((x) => {
    return regionIdArray.includes(x.regionId);
  });
};

const filterMarkets = (filterOptions) => {
  const userFilterSelections = filterOptions.userFilterSelections;
  const markets = filterOptions.markets;

  if (!userFilterSelections) return formatOptions(markets, "market");

  if (hasRegionSelections(userFilterSelections)) {
    const selectedRegionIds = userFilterSelections.regions;
    const selectedMarkets = getMarketsFromRegionIds(selectedRegionIds, markets);
    return formatOptions(selectedMarkets, "market");
  }

  return formatOptions(filterOptions.markets, "market");
};

const filterCommunities = (filterOptions: GetFilterOptionsBody): Option[] => {
  const userFilterSelections = filterOptions.userFilterSelections;
  const communities = filterOptions.communities;
  const markets = filterOptions.markets;

  if (!userFilterSelections) return formatOptions(communities, "community");

  if (hasMarketSelections(userFilterSelections)) {
    const selectedMarketIds = userFilterSelections.markets;
    const selectedCommunities = getCommunitiesFromMarketIds(
      communities,
      selectedMarketIds
    );
    return formatOptions(selectedCommunities, "community");
  }

  if (hasRegionSelections(userFilterSelections)) {
    const selectedRegionIds = userFilterSelections.regions;
    const selectedMarketIds = getMarketIdsFromRegionIds(
      selectedRegionIds,
      markets
    );
    const selectedCommunities = getCommunitiesFromMarketIds(
      communities,
      selectedMarketIds
    );
    return formatOptions(selectedCommunities, "community");
  }
  return formatOptions(filterOptions.communities, "community");
};

const getPhases = (filterOptions, communities) => {
  const { communities: _communities } = filterOptions;
  const communityDetails = _communities.filter((x) =>
    communities.includes(x.communityId)
  );

  const phases = communityDetails.map((x) => {
    // gather phases if available
    if (x.properties && x.properties.length) {
      return x.properties.map((p) => p.propertyId.replace("AVB-", ""));
    }
    return x.communityId;
  });
  return phases.flat();
};

export {
  filterCommunities,
  filterMarkets,
  getPhases,
  selections2Options,
  options2String,
  formatArrayToString,
  formatOptions,
};
