import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { convertToLocalAndFormatDate, formatTime } from "src/utils/Utils";
import CachedIcon from "@mui/icons-material/Cached";

interface LastFetchedDateProps {
  date: Date | string;
  onRefresh: () => void;
}
export const LastFetchedDate = ({ date, onRefresh }: LastFetchedDateProps) => {
  if (!date) {
    return null;
  }
  const dateString = date
    ? `${formatTime(date)}, ${convertToLocalAndFormatDate(date)}`
    : "";

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      flexWrap="nowrap"
      justifyContent="end"
    >
      <div>
        <Typography
          variant="subtitle2"
          sx={{
            color: "#72767F",
            cursor: "pointer",
          }}
        >
          Last updated: {dateString}
        </Typography>
      </div>
      <Tooltip
        arrow
        title="Reload data"
        placement="top"
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "common.white",
              color: "common.black",
              boxShadow: "0px 1px 1px 1px #a3a3a3",
              "& .MuiTooltip-arrow": {
                color: "common.white",
              },
            },
          },
          arrow: {
            sx: {
              "&::before": {
                boxShadow: "0px 1px 1px 1px #a3a3a3",
              },
            },
          },
        }}
      >
        <IconButton onClick={onRefresh}>
          <CachedIcon />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export default LastFetchedDate;
