import {
  AddendumsResponse,
  Addendums,
  AddendumsPostAddendums,
  MapAssociateActionToAnswerIds,
  AddendumsAnswerId,
} from "../types/Addendum";

const getAddendumAssociateAction = (
  responses: AddendumsResponse[] | undefined
): string => {
  if (!responses || !responses.length) return "N/A";
  return responses.reduce((action, response) => {
    if (action) return action;
    if (response.answerId && response.answerValue) {
      Object.entries(MapAssociateActionToAnswerIds).some(
        ([associateAction, answerIds]) => {
          if (answerIds.includes(response.answerId as AddendumsAnswerId)) {
            action = associateAction;
            return true;
          }
        }
      );
    }
    return action;
  }, "");
};

const mergeAddendumFormsAndResponses = (
  addendums: Addendums,
  formResponses: AddendumsPostAddendums[]
): Addendums => {
  const newAddendums = {
    ...addendums,
    forms: addendums.forms?.map((form) => {
      const responses = formResponses.find(
        (response) => response.formName === form.formName
      )?.responses;
      if (responses) {
        form.responses = responses;
      }
      return form;
    }),
  };
  return newAddendums;
};

export { getAddendumAssociateAction, mergeAddendumFormsAndResponses };
